import {
    filter,
    get,
    last,
    map,
    sumBy,
    takeRight,
    flatten,
    defaultTo,
    size,
    cloneDeep,
    reduce,
    findIndex,
    uniq, forEach
} from 'lodash';
import { proto } from '../services/AsrProto';
import { safeStringify } from '.';
import { captureWarnMessage } from '../../tracker/raven';
import { getEnabledASRVendor } from '../store/selectors';
import { recLogger } from '../../utils/logger';
import {EXPLICIT_INTEGRATION_SOURCE, KEYPHRASE_GROUP_NAME_FOR_START, KEYPHRASE_GROUP_NAME_FOR_STOP} from "../constants";

export const HISTORY_LENGTH = 500;

function mergeKWObjects(obj, intoObj) {
    const iter = (result, value, key) => ({
        ...result,
        [key]: defaultTo(result[key], 0) + value
    });
    return reduce(obj, iter, { ...intoObj });
}

export function mergeSpottedLists(initial = {}, target = {}) {
    return {
        whitelist: mergeKWObjects(initial.whitelist, target.whitelist),
        blacklist: mergeKWObjects(initial.blacklist, target.blacklist)
    };
}

export function kwObjectToList(obj) {
    return reduce(obj, (result, count, text) => result.concat({ text, count }), []);
}

export function refreshPreviousVendorResult(currentVendorResults) {
    const def = { wordsPerMinute: null };
    const partial = { };
    return Object.assign(defaultTo(currentVendorResults, def), partial);
}

export function getSpottedPhrases(spottedResult = {}) {
    const result = {
        whitelist: {},
        blacklist: {}
    }
    forEach(spottedResult.whitelist, (phrase => {
        phrase.segments.forEach(segment => {
            result.whitelist[segment] = true;
        })
    }));

    forEach(spottedResult.blacklist, (phrase => {
        phrase.segments.forEach(segment => {
            result.blacklist[segment] = true;
        })
    }));

    return result;
}

export function setResult(vendorsResults, asrResult) {
    const { final, partial } = asrResult;

    return {
        ...vendorsResults,
        wordsPerMinute: get(final, 'alternatives[0].wordsPerMinute') || get(partial, 'wordsPerMinute'),
    };
}

function getWordConfidence(alternative, normWord, words) {
    if (!words.length) {
        console.warn(`Alternative words: ${safeStringify(alternative.words)}`);
        console.warn(`Word: ${safeStringify(normWord)}`);
        captureWarnMessage('There are no words to calculate confidence');
        return 0;
    }
    return sumBy(words, item => item.validationResult.validationScore) / words.length;
}

export function getTimelineTranscript(result) {
    const alternative = result.final.alternatives[0];

    const preparedWords = alternative.normWords.map(normWord => {
        const createPronunciation = phone => ({
            sound: phone.phone.split('_')[0],
            confidence: phone.validationResult.validationScore
        });
        const words = alternative.words.slice(normWord.span.begin, normWord.span.end);
        const phonesList = map(words, 'phones');
        const pronunciation = flatten(
            map(phonesList, phones => map(phones, createPronunciation))
        );

        return {
            normalizedWord: normWord.word,
            words,
            confidence: getWordConfidence(alternative, normWord, words),
            punctuation: normWord.punctuation,
            pronunciation
        };
    });

    return {
        normalizedTranscript: alternative.normalizedTranscript,
        startOffset: result.timings.utteranceStartTimeMsec
            ? result.timings.utteranceStartTimeMsec
            : 0,
        endOffset: result.timings.currentTimeOffsetMsec,
        words: preparedWords
    };
}

const selectNormalizedTranscript = data =>
    get(data, 'final.alternatives[0].normalizedTranscript');

export function processTextResult(asrResult, previousVendorsResult) {
    return {
        ...setResult(refreshPreviousVendorResult(previousVendorsResult), asrResult),
        timeOffset: asrResult.timings.currentTimeOffsetMsec,
    }
}

function buildTimelineTranscript(asrResult) {
    const normalizedTranscript = selectNormalizedTranscript(asrResult);

    if (size(normalizedTranscript) > 0) {
        return getTimelineTranscript(asrResult);
    }

    return null;
}

export function processAsrMessage(asrResult, storeRawData) {
    const vendor = proto.Vendor[asrResult.vendor].toLowerCase();
    const vendorResults = processTextResult(asrResult, storeRawData[vendor]);

    return {
        updatedResults: {
            speechRate: vendorResults.wordsPerMinute,
            timeOffset: vendorResults.timeOffset
        },
        currentResults: {
            ...storeRawData,
            [vendor]: vendorResults,
        }
    };
}

export function validateSpottingConfig(spottingConfig) {
    const transform = group => {
        return {
            ...group,
            head: uniq(group.head),
            complement: uniq(group.complement)
        }
    };

    return {
        ...spottingConfig,
        whitelist: spottingConfig.whitelist.map(transform),
        blacklist: spottingConfig.blacklist.map(transform)
    };
}

export function getSpottingConfigTotalLength(spottingConfig) {
    return spottingConfig.reduce((acc, item) => item.phrases.length + acc, 0);
}

function mergeLists(currentList, updateList, type = null) {
    const findItemIndex = (list, item) => {
        const itemText = item.text.toLowerCase();
        const comparator = ({ text }) => text.toLowerCase() === itemText;
        return findIndex(list, comparator);
    };
    const update = list => item => {
        const index = findItemIndex(list, item);
        Object.assign(item, { count: get(list[index], 'count', 0) });
    };

    const result = cloneDeep(currentList);

    if (type === 'whitelist') {
        result.forEach(({ alternatives }) => alternatives.forEach(update(updateList)));
        result.forEach(({ complements = [] }) => complements.forEach(update(updateList)));
    } else {
        result.forEach(update(updateList));
    }

    return result;
}

function calculatePhraseGroupCount(whitelist) {
    return whitelist.map(phraseGroup => {
        const result = phraseGroup;
        result.count = sumBy(phraseGroup.alternatives, 'count');
        return result;
    });
}

function updateHistory(resultsHistory, newResults, timeOffset) {
    const history = takeRight(resultsHistory, HISTORY_LENGTH - 1);
    return history.concat({ ...newResults, timeOffset });
}

export function updateTranscript(timelineTranscripts, type, asrResult) {
    const item = buildTimelineTranscript(asrResult);
    if (item != null) {
        return defaultTo(timelineTranscripts, []).concat({ type, ...item });
    }
    return timelineTranscripts;
}

function combineResults(micResults, { speechRate, whitelist, blacklist }) {
    return {
        ...micResults,
        speechRate,
        whitelist: calculatePhraseGroupCount(
            mergeLists(micResults.whitelist, whitelist, 'whitelist')
        ),
        blacklist: mergeLists(micResults.blacklist, blacklist)
    };
}

export function updateMicResults(state, asrResult) {
    if (!asrResult) {
        return { micResultsRaw: null };
    }

    const asrData = processAsrMessage(asrResult, cloneDeep(state.micResultsRaw || {}));
    const { timeOffset, speechRate } = asrData.updatedResults;
    const micResults = { ...state.micResults, speechRate }

    const micResultsHistory = updateHistory(
        state.micResultsHistory,
        micResults,
        timeOffset
    );

    return {
        micResults,
        micResultsRaw: asrData.currentResults,
        micResultsHistory
    };
}

export function updatePhraseSpottingResults(state, spotResult) {
    if (!spotResult) {
        return { micResultsRaw: null };
    }

    let vendor;

    try {
        [vendor,] = getEnabledASRVendor(get(state, 'config.asr'))
    } catch (err) {
        recLogger.error(`Failed to update phrase spotting results, ASR vendor not resolved`);
        return;
    }

    const spotPhrases = getSpottedPhrases(spotResult);
    const storeRawData = cloneDeep(state.micResultsRaw || {});
    const previousVendorsResult = storeRawData[vendor];
    const newVendorResults = refreshPreviousVendorResult(previousVendorsResult);
    const spotResults = {
        finalSpotted: mergeSpottedLists(newVendorResults.finalSpotted, spotPhrases),
        partialSpotted: mergeSpottedLists(newVendorResults.partialSpotted, spotPhrases),
    };
    const { whitelist, blacklist } = mergeSpottedLists(
        spotResults.finalSpotted,
        spotResults.partialSpotted
    );

    const asrData = {
        updatedResults: {
            whitelist: kwObjectToList(whitelist),
            blacklist: kwObjectToList(blacklist),
        },
        currentResults: {
            ...storeRawData,
            [vendor]: { ...previousVendorsResult, ...spotResults },
        }
    };
    const micResults = combineResults(state.micResults, asrData.updatedResults);
    const micResultsHistory = updateHistory(
        state.micResultsHistory,
        micResults,
    );

    return {
        micResults,
        micResultsRaw: asrData.currentResults,
        micResultsHistory,
    };
}

export function prepareWhiteList(spottingConfig) {
    const phrases = get(spottingConfig, 'whitelist', []);

    const iter = (groups, { groupName, points, head, complement }) => {
        const next = {
            ...groups,
            [groupName]: groups[groupName] || {
                text: groupName,
                count: 0,
                alternatives: [],
                complements: []
            }
        };
        head.forEach(text => {
            next[groupName].alternatives.push({ text, points, count: 0 });
        })
        complement.forEach(text => {
            next[groupName].complements.push({ text, points, count: 0 });
        })
        return next;
    };

    return Object.values(reduce(phrases, iter, {}));
}

export function prepareBlackList(spottingConfig) {
    const phrases = get(spottingConfig, 'blacklist', []);

    const iter = (groups, { head }) => {
        const next = {
            ...groups,
        };
        head.forEach((text) => {
            next[text] = {
                text,
                count: 0,
                alternatives: [],
                complements: []
            }
        })
        return next;
    };

    return Object.values(reduce(phrases, iter, {}));
}

export function getDefaultMicResults(spottingConfig) {
    return {
        speechRate: null,
        whitelist: prepareWhiteList(spottingConfig),
        blacklist: prepareBlackList(spottingConfig)
    };
}

export function onCallEnded(state, payload) {
    const callDuration = payload.sessionEndOffsetMsec - state.sessionStartOffsetMsec;
    const micResults =
        last(filter(state.micResultsHistory, item => {
            return !item.timeOffset || item.timeOffset <= callDuration
        })) ||
        getDefaultMicResults(state.config.spottingConfig);
    const timelineTranscripts = filter(
        state.timelineTranscripts,
        item => item.startOffset < callDuration
    );
    return { ...state, micResults, timelineTranscripts, battleCard: null };
}

export const updateVoiceMetrics = (metrics, payload) => ({
    ...metrics,
    speechRatio: payload.speechRatio,
    loudness: payload.loudness
});

/**
 * saveRealtimeCallChecker returns a boolean value indicating if a call data must be saved
 * based on the real-time % setting
 */
export const saveRealtimeCallChecker = (realtimeOnlyCallsPercent = 0) => {
    if (realtimeOnlyCallsPercent === 0) {
        return true;
    }

    const modifier = Math.abs(realtimeOnlyCallsPercent);
    const rng = Math.floor(Math.random() * 100);

    return rng > modifier
};

export const normalizeSpottingConfig = (spottingConfig) => {
    return {
        whitelist: spottingConfig.whitelist.map(p => ({
            ...p,
            visible: true,
        })),
        blacklist: spottingConfig.blacklist.map(p => ({
            ...p,
            visible: true,
        })),
    }
}

export const isKeyphraseIntegrationMode = (appConfig) => {
    return appConfig?.callDetection?.explicitIntegrationSource === EXPLICIT_INTEGRATION_SOURCE.KEYPHRASE_AUDIO_EVENT;
}

export const pathSpottingConfigForKeyphraseIntegrationMode = (spottingConfig) => {
    return {
        whitelist: spottingConfig.whitelist.map(phrase => {
            if(phrase.groupName === KEYPHRASE_GROUP_NAME_FOR_STOP || phrase.groupName === KEYPHRASE_GROUP_NAME_FOR_START){
                return {
                    ...phrase,
                    head: phrase.head.filter(p => p !== phrase.groupName),
                    points: 0,
                    visible: false,
                }
            }
            return phrase;
        }),
        blacklist: spottingConfig.blacklist,
    }
}



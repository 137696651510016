import {types as userTypes} from "../actions/user";
import {getUserCompanyName, getUserEmail, getUserName, isMainConnectionOpen, isProfileLoaded} from "../selectors";
import {startMainConnection, initListener, types as mainTypes} from "../actions/main";

export default store => {
    const { dispatch, getState } = store;
    return next => action => {
        const nextAction = next(action);

        switch (action.type) {
            case userTypes.GET_USER_PROFILE_SUCCESS: {
                if (!isMainConnectionOpen(getState())) {
                    dispatch(startMainConnection())
                } else {
                    dispatch(initListener())
                }

                const state = getState();

                // set user info to global object
                window.user = {
                    companyName: getUserCompanyName(state),
                    userName: getUserName(state) || getUserEmail(state)
                }
                break;
            }
            case mainTypes.MAIN_CONNECTION_OPENED: {
                if (isProfileLoaded(getState())) {
                    dispatch(initListener())
                }
                break;
            }
            default:
        }

        return nextAction;
    }
};

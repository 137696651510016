import {includes} from 'lodash';
import {actionLogger, recorderLogger} from '../../../utils/logger';
import {types as callTypes} from '../actions/call';
import {types as deviceTypes} from '../actions/device';
import {types as asrTypes} from '../actions/asr';
import {types as commonTypes} from '../actions/common';
import {types as mainTypes} from '../actions/main';
import {logUserEvent} from "../actions/eventLogger";
import {getCallId} from "../selectors";

const blackList = [
    deviceTypes.AUDIO_CHUNK_RECEIVED,
    asrTypes.SOUND_CHUNK,
    asrTypes.SPEAKER_UPDATE_ASR_RESULTS,
    asrTypes.SPEAKER_UPDATE_ASR_VOICE_METRICS,
    asrTypes.MIC_UPDATE_ASR_RESULTS,
    asrTypes.MIC_UPDATE_PHRASE_SPOTTING_RESULTS,
    asrTypes.MIC_UPDATE_ASR_VOICE_METRICS,
    callTypes.CALL_TIMER_UPDATED,
    commonTypes.UPDATE_RELOAD_COUNT_DOWN,
    mainTypes.EVENT_FORWARDED
];

if(window.electronAPI?.onLogMessage){
    window.electronAPI.onLogMessage(([type, msg]) => {
        if(recorderLogger[type]) recorderLogger[type](msg)
    });
}

export default store => {
    const { dispatch, state } = store;

    if(window.electronAPI?.onUserEvent){
        window.electronAPI.onUserEvent(([userEvent]) => {
            dispatch(logUserEvent(userEvent, getCallId(state)));
        });
    }

    return next => action => {
        const {type, payload, error} = action;

        if (!includes(blackList, type)) {
            if (!error) {
                actionLogger.log(type, payload);
            } else {
                actionLogger.error(type, error.message, error);
            }
        }

        return next(action);
    }
};
